<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="575">
      <v-card class="pa-md-6 mx-lg-auto">
        <v-card-title class="grey lighten-2 subtitle-2">
          <v-row>
            <v-col cols="12" md="12" class="text-center">
              <span>
                <b> {{ data.account.company.second_name }} </b>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <span> <b>No. CONCILIACIÓN: </b> {{ data.code }} </span>
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <span> <b>BANCO: </b> {{ data.account.bank.name }} </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <span>
                <b>PERIODO: </b> {{ data.period.month }}-{{ data.period.year }}
              </span>
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <span> <b>CUENTA: </b> {{ data.account.code }} </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-row class="text-center">
          <v-col cols="12" md="6"
            >SALDO INICIAL:
            <v-icon
              v-if="data.editable"
              medium
              class="mr-2"
              color="primary"
              dark
              @click="updateAccount(0)"
              >mdi-reload</v-icon
            >
          </v-col>
          <v-col cols="12" md="6">Q. {{ data.initial_balance | currency }}</v-col>
          <v-col cols="12" md="6">(+)CREDITOS:</v-col>
          <v-col cols="12" md="6">Q. {{ data.credits | currency }}</v-col>
          <v-col cols="12" md="6">DISPONIBILIDAD</v-col>
          <v-col cols="12" md="6"
            >Q.
            {{
              Number(Number(data.initial_balance) + Number(data.credits)).toFixed(2)
                | currency
            }}</v-col
          >
          <v-col cols="12" md="6">(-)DÉBITOS:</v-col>
          <v-col cols="12" md="6">Q. {{ data.debits | currency }}</v-col>
          <v-col cols="12" md="6">SALDO SEGÚN LIBRO:</v-col>
          <v-col cols="12" md="6">Q. {{ data.ending_balance | currency }}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row> </v-row>
        <v-divider></v-divider>
        <br />
        <v-row class="text-center">
          <v-col cols="12" md="6">CHEQUES EN CIRCULACIÓN:</v-col>
          <v-col cols="12" md="6">Q. {{ data.floating_checks | currency }}</v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close()"> Cerrar </v-btn>
          <v-btn v-if="data.editable" color="primary" text @click="updateAccount(1)">
            Finalizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/services/requests";
// import rules from "@/utils/form.rules";

export default {
  props: {
    dialog: { type: Boolean, required: true },
    month: { type: String, required: true },
    year: { type: String, required: true },
    account: { type: String, required: true },
  },
  components: {},
  data() {
    return {
      data: {
        id: 0,
        account: {
          id: null,
          bank: { name: null },
          company: { second_name: null },
          code: null,
        },
        period: { code: null, year: null, month: null },
        code: null,
        initial_balance: null,
        ending_balance: null,
        credits: 0,
        debits: 0,
        editable: false,
        floating_checks: null,
      },
    };
  },
  methods: {
    getAccountStatus() {
      requests
        .get(
          "api/accounting/account-status/?account=" +
            this.account +
            "&year=" +
            this.year +
            "&month=" +
            this.month
        )
        .then((res) => {
          //   console.log(res);
          if (res.status == 200) {
            this.data = res.data.results[0];
          } else {
            this.$toasted.global.info({
              message: res.data.detail,
            });
            this.close();
          }
        });
    },
    close() {
      this.$emit("close");
      this.data = {
        id: 0,
        account: {
          id: null,
          bank: { name: null },
          company: { second_name: null },
          code: null,
        },
        period: { code: null, year: null, month: null },
        code: null,
        initial_balance: null,
        ending_balance: null,
        credits: 0,
        debits: 0,
        editable: false,
        floating_checks: null,
      };
    },
    updateAccount(option) {
      // 0 traer saldo inicial
      // 1 finalizar
      var data = {};
      if (option == 0) {
        data = { update: true };
      } else if (option == 1) {
        data = { editable: false };
      }

      requests
        .patch(
          "api/accounting/account-status/" +
            this.data.code +
            "/?account=" +
            this.data.account.id,
          data
        )
        .then((res) => {
          if (res.status == 200) {
            if (option == 1) {
              this.$toasted.global.info({
                message: "La conciliación " + this.data.code + " ha sido finalizada",
              });
            }
            this.getAccountStatus();
          } else {
            console.log(res);
            this.$toasted.global.info({
              message: res.data.detail,
            });
          }
        });
    },
  },
  watch: {},
  mounted() {
    this.getAccountStatus();
  },
};
</script>
